jQuery( document ).ready( function ( $ ) {
	$( 'form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( 'form' ).replaceWith( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				alert( 'An error has occurred!' );
			}
		} );
	} );

} );
